@use "../mixins/media-queries" as *;

@mixin _base-text-styles {
  font-weight: normal;
  line-height: 1.5em;
  color: var(--primary-text-color);
}

.text-1 {
  @include _base-text-styles;
  font-size: 1rem;

  @include tablet-portrait-and-up {
    font-size: 1.125rem;
  }

  @include desktop-and-up {
    font-size: 1.25rem;
  }
}

.text-2 {
  @include _base-text-styles;
  font-size: 0.875rem;

  @include tablet-portrait-and-up {
    font-size: 1rem;
  }

  @include desktop-and-up {
    font-size: 1.125rem;
  }
}

.text-3 {
  @include _base-text-styles;
  font-size: 0.75rem;

  @include tablet-portrait-and-up {
    font-size: 0.875rem;
  }

  @include desktop-and-up {
    font-size: 1rem;
  }
}

.text-4 {
  @include _base-text-styles;
  font-size: 0.75rem;

  @include desktop-and-up {
    font-size: 0.875rem;
  }
}

.text-5 {
  @include _base-text-styles;
  font-size: 0.75rem;
}
