@use "title-regular" as *;
@use "../mixins/font" as font;
@use "../mixins/media-queries" as *;

.title-1-bold {
  @extend .title-1-regular;
  @include font.bold;
  font-size: 1.875rem;
  line-height: 1.4;
  letter-spacing: -0.02rem;

  @include tablet-portrait-and-up {
    font-size: 2.25rem;
    line-height: 1.15;
  }

  @include desktop-and-up {
    font-size: 3.5rem;
    letter-spacing: 0;
  }
}

.title-2-bold {
  @extend .title-2-regular;
  @include font.bold;
  font-size: 1.5rem;
  line-height: 1.4;
  letter-spacing: -0.02rem;

  @include tablet-portrait-and-up {
    line-height: 1.125;
    font-size: 2rem;
  }

  @include desktop-and-up {
    letter-spacing: 0;
    font-size: 2.5rem;
  }
}

.title-3-bold {
  @extend .title-3-regular;
  @include font.bold;
  font-size: 2.25rem;
  letter-spacing: -0.01rem;

  @include tablet-portrait-and-up {
    font-size: 2.375rem;
  }

  @include desktop-and-up {
    font-size: 2rem;
  }
}

.title-4-bold {
  @extend .title-4-regular;
  @include font.bold;
}

.title-5-bold {
  @extend .title-5-regular;
  @include font.bold;
}

.title-6-bold {
  @extend .title-6-regular;
  @include font.bold;
}

.title-7-bold {
  @extend .title-7-regular;
  @include font.bold;
}

.title-8-bold {
  @extend .title-8-regular;
  @include font.bold;
}

.title-9-bold {
  @extend .title-9-regular;
  @include font.bold;
}