@use "../mixins/media-queries" as *;
@use "../mixins/font" as font;

.title-1-regular {
  @include font.regular;
  font-size: 1.75rem;

  @include tablet-portrait-and-up {
    font-size: 2.25rem;
  }

  @include desktop-and-up {
    font-size: 2.75rem;
  }
}

.title-2-regular {
  @include font.regular;
  font-size: 1.5rem;

  @include tablet-portrait-and-up {
    font-size: 2rem;
  }

  @include desktop-and-up {
    font-size: 2.5rem;
  }
}

.title-3-regular {
  @include font.regular;
  font-size: 1.375rem;

  @include tablet-portrait-and-up {
    font-size: 1.5rem;
  }

  @include desktop-and-up {
    font-size: 2rem;
  }
}

.title-4-regular {
  @include font.regular;
  font-size: 1.25rem;

  @include desktop-and-up {
    font-size: 1.5rem;
  }
}

.title-5-regular {
  @include font.regular;
  font-size: 1.125rem;

  @include desktop-and-up {
    font-size: 1.25rem;
  }
}

.title-6-regular {
  @include font.regular;
  font-size: 1rem;

  @include desktop-and-up {
    font-size: 1.125rem;
  }
}

.title-7-regular {
  @include font.regular;
  font-size: 0.875rem;

  @include desktop-and-up {
    font-size: 1rem;
  }
}

.title-8-regular {
  @include font.regular;
  font-size: 0.75rem;

  @include tablet-portrait-and-up {
    font-size: 0.875rem;
  }
}

.title-9-regular {
  @include font.regular;
  font-size: 0.625rem;

  @include tablet-portrait-and-up {
    font-size: 0.75rem;
  }
}
