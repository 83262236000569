@import "breakpoints";

@mixin dark-mode {
  .theme-auto {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }

  .enforced-dark {
    @content;
  }
}

@mixin light-mode {
  .theme-auto {
    @media (prefers-color-scheme: light) {
      @content;
    }
  }

  .enforced-light {
    @content;
  }
}

@mixin media-up-down($min, $max) {
  @media (min-width: $min) and (max-width: $max - $_one_px_in_rem) {
    @content;
  }
}

@mixin media-up($b) {
  @media (min-width: $b) {
    @content;
  }
}

@mixin media-down($b) {
  @media (max-width: $b - $_one_px_in_rem) {
    @content;
  }
}

// use this to debug the elements :)
@mixin media-debug() {
  background: silver;
  @include media-up($breakpoint-tablet_portrait) {
    background: red;
  }
  @include media-up($breakpoint-tablet_landscape) {
    background: yellow;
  }
  @include media-up($breakpoint-desktop) {
    background: green;
  }
}

@mixin mobile-only {
  @include media-down($breakpoint-tablet_portrait) {
    @content;
  }
}

@mixin up-to-tablet-portrait {
  @include media-down($breakpoint-tablet_landscape) {
    @content;
  }
}

@mixin tablet-portrait-only {
  @include media-up-down(
    $breakpoint-tablet_portrait,
    $breakpoint-tablet_landscape
  ) {
    @content;
  }
}

@mixin tablet-portrait-and-up {
  @include media-up($breakpoint-tablet_portrait) {
    @content;
  }
}

@mixin tablet-landscape-only {
  @include media-up-down($breakpoint-tablet_landscape, $breakpoint-desktop) {
    @content;
  }
}

@mixin up-to-tablet-landscape {
  @include media-down($breakpoint-desktop) {
    @content;
  }
}

@mixin tablet-landscape-and-up {
  @include media-up($breakpoint-tablet_landscape) {
    @content;
  }
}

@mixin desktop-and-up {
  @include media-up($breakpoint-desktop) {
    @content;
  }
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
