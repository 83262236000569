@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";

.xpaLayout {
  --containers-vertical-gap: 2.5em;

  :global {
    // default gap between the header and the first XPA layout container
    .xpaLayoutContainer:first-of-type {
      :local(.xpaLayoutContainerComponentResolver):first-child {
        display: block;
        --gap: 1em;
        padding-top: var(--gap);

        @include tablet-portrait-and-up {
          --gap: 1.25em;
        }
      }
    }
  }
}

.xpaLayoutContainerFullWidth {
  & + .xpaLayoutContainerFullWidth,
  & + .xpaLayoutContainerGrid {
    margin-top: var(--containers-vertical-gap);
  }
}

.xpaLayoutContainerGrid {
  @include max-root-width.limit;

  --of-grid-gap-size: 1rem;

  display: grid;
  grid-gap: var(--of-grid-gap-size);

  // minmax is required to fix a bug in Firefox (css-tricks.com/preventing-a-grid-blowout)
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @include tablet-landscape-only {
    --of-grid-gap-size: 1.5rem;
  }

  @include desktop-and-up {
    --of-grid-gap-size: 2.5rem;
  }

  & + .xpaLayoutContainerFullWidth,
  & + .xpaLayoutContainerGrid {
    margin-top: var(--containers-vertical-gap);
  }

  grid-column-gap: 1.5rem;
  grid-row-gap: 2.5rem;

  @include tablet-landscape-and-up {
    grid-row-gap: 1.875rem;
  }
}

.xpaLayoutContainerGridItem {
  @include mobile-only {
    grid-column: var(--grid-column-span-mobile);
    grid-row: var(--grid-row-span-mobile);
  }

  @include tablet-portrait-only {
    grid-column: var(--grid-column-span-tablet_portrait);
    grid-row: var(--grid-row-span-tablet_portrait);
  }

  @include tablet-landscape-only {
    grid-column: var(--grid-column-span-tablet_landscape);
    grid-row: var(--grid-row-span-tablet_landscape);
  }

  @include desktop-and-up {
    grid-column: var(--grid-column-span-desktop);
    grid-row: var(--grid-row-span-desktop);
  }
}

.xpaLayoutContainerGridItemComponents {
  min-width: 0;
  height: 100%;

  & > * + * {
    display: block;
    margin-top: 1.25em;
  }
}

@mixin _xpa-layout-sticky {
  position: sticky;
  top: 0;
}

.xpaLayoutContainerGridItemComponentsMobileSticky {
  @include mobile-only {
    @include _xpa-layout-sticky;
  }
}

.xpaLayoutContainerGridItemComponentsTabletPortraitSticky {
  @include tablet-portrait-only {
    @include _xpa-layout-sticky;
  }
}

.xpaLayoutContainerGridItemComponentsTabletLandscapeSticky {
  @include tablet-landscape-only {
    @include _xpa-layout-sticky;
  }
}

.xpaLayoutContainerGridItemComponentsDesktopSticky {
  @include desktop-and-up {
    @include _xpa-layout-sticky;
  }
}

.xpaLayoutContainerGridItemComponent {
  display: block;

  & + & {
    margin-top: 1.25rem;
  }
}

@include mobile-only {
  .xpaLayoutContainerGridItemMobileHidden {
    display: none;
  }
}

@include tablet-portrait-only {
  .xpaLayoutContainerGridItemTabletPortraitHidden {
    display: none;
  }
}

@include tablet-landscape-only {
  .xpaLayoutContainerGridItemTabletLandscapeHidden {
    display: none;
  }
}

@include desktop-and-up {
  .xpaLayoutContainerGridItemDesktopHidden {
    display: none;
  }
}

.pageLoadingProgressBarContainer {
  z-index: var(--layout-progress-bar-index);
  position: fixed;
  top: 0;
  width: 100vw;
  display: none;
}
