@use "@/styles/mixins/media-queries" as *;


.wrapper {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-radius: var(--rounded-corner);
  background-color: var(--brand-magenta);
  padding: 0.5rem;
  vertical-align: middle;
}

.small {
  padding: 0.375rem;
  .dot {
    height: 0.75rem;
    width: 0.75rem;
  }
}

@keyframes dot-pulse {
  0% {opacity:1} 50% {opacity:.4} to {opacity:1}
}

.dot {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: radial-gradient(circle, transparent 52%, var(--primary-background-color) 54%);
}

.dot:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: dot-pulse 2s ease infinite;
  background: radial-gradient(circle, var(--primary-background-color) 36%, transparent 38%);
  left: 0;
}

.text {
  text-transform: uppercase;
  color: var(--primary-background-color);
  line-height: 0.75;
  margin-left: 0.5rem;
}
