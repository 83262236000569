.container {
    display: inline-flex;
    place-items: center;
    min-height: 2rem;
    text-decoration: none;
    transition: color 300ms ease-in-out;
    color: var(--label-text-color);

    @media (hover: hover) {
        &:hover {
            color: var(--interaction-unactive-state-color);
        }
    }
}

.image {
    opacity: 0.7;
    --arrow-size: 1.5rem;
    width: var(--arrow-size);
    height: var(--arrow-size);

    [dir="rtl"] & {
        transform: rotate(180deg);
    }
}

