@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/teaser.mixins" as *;
@use "@/styles/mixins/line-clamp" as *;


/* This component has 5 types of layout: X-SMALL (xs) |  SMALL (sm) |  MEDIUM (md) |  LARGE (lg) | X-LARGE (xl)
  and they have different compositions based on the screen size: mobile | tablet_portrait | tablet_landscape | desktop.
 But some layouts can be the same on some devices, for example,  MEDIUM (md) Layout uses the same compositions as
 X-SMALL on device for mobile device.
*/
.teaser {
  --teaser-border: 1px; // border size
  --padding-size: 1rem;
  --border: var(--teaser-border) solid var(--divider-color);

  /*
  Variable to calculate negative margin, to allow images to stretch out
  the margins and paddings
   */
  --streach-size-with-padding: calc(-1 * (var(--padding-size) + var(--teaser-border)));

  display: grid;
  @include tile-title-font-sizes;
  height: 100%;

  @media tablet-landscape-and-up {
    --padding-size: 1.5rem;
  }
}

.teaser--mobile {
  // On mobile xs - sm -md and lg have the same layout
  @include mobile-only {

    &--xs,
    &--sm,
    &--md,
    &--lg {
      @include xs-mobile-grid;
      gap: 1rem;

      .teaser__title {
        @include line-clamp(3);
      }

      .teaser__footer {
        align-items: center;
      }

      .teaser__imageWrapper{
        width:100%;
        aspect-ratio: 1/1; //the image has to be a square
      }

      .teaser__playVideoIcon {
        --play-video-icon-size: 1.5rem;
      }
    }

    // xl uses on mobile the same layout as xs in tablet portrait
    &--xl {
      @include xs-tablet-portrait-and-up-grid;
      gap: 1rem;
    }

    &--lg,
    &--md,
    &--sm {
      .teaser__videoDuration {
        font-size: 0.75rem;
      }
    }

    &--xs {
      .teaser__videoDuration {
        display: none;
      }
      .liveImageMargin {
        margin-top: 2rem;
      }
    }

  }
}

.teaser--tablet-portrait {
  @include tablet-portrait-only {

    &--xs,
    &--sm,
    &--md,
    &--lg {
      @include xs-tablet-portrait-and-up-grid;
      gap: var(--padding-size);
      border-radius: var(--rounded-corner);

      .teaser__title {
        @include line-clamp(3);
        display: block;
      }
    }

    &--xl {
      @include lg-tablet-landscape-and-up-grid;
      @include lg-tablet-landscape-and-up-styles;
      .teaser__playVideoIcon {
        --play-video-icon-size: 4.375rem;
      }

      .teaser__title,
      .teaser__preview {
        @include line-clamp(5);
      }
    }

    &--lg {
      .teaser__title,
      .teaser__preview {
        @include line-clamp(3);
      }
    }

    &--md,
    &--sm,
    &--xs {
      .teaser__playVideoIcon {
        --play-video-icon-size: 3.125rem;
      }

      .teaser__videoDuration {
        font-size: 0.75rem;
      }
    }

    /*
    On tablet portrait md is a bit different than xs and sm
    It has a padding on the title and a border, giving it a card-ish style
    */
    &--md {
      @include xs-tablet-portrait-and-up-with-border;
    }
  }
}

.teaser--tablet-landscape {
  @include tablet-landscape-only {
    &--xs,
    &--sm {
      @include xs-tablet-portrait-and-up-grid;
      gap: var(--padding-size);
      border-radius: var(--rounded-corner);
    }

    &--sm {
      @include xs-tablet-portrait-and-up-with-border;

      .teaser__title {
        @include line-clamp(3);
      }
    }

    &--md,
    &--lg,
    &--xl {
      @include lg-tablet-landscape-and-up-grid;
      @include lg-tablet-landscape-and-up-styles;

      .teaser__playVideoIcon {
        --play-video-icon-size: 4.375rem;
      }

      .teaser__title {
        @include line-clamp(6);
        display: block;
      }

      .teaser__preview {
        @include line-clamp(4);
        display: block;
      }

      .teaser__videoDuration {
        font-size: 1rem;
      }
    }

    &--xl {
      --image-percentage: 65.63%;
    }

    &--lg {
      --image-percentage: 55%;
    }

    &--sm {
      .teaser__playVideoIcon {
        --play-video-icon-size: 3.75rem;
      }
    }

    &--sm,
    &--xs {
      .teaser__title {
        @include line-clamp(3);
        display: block;
      }
    }

    &--md {
      --image-percentage: 47%;

      .teaser__title,
      .teaser__preview {
        @include line-clamp(3);
        display: block;
      }
    }

    &--xs {
      .teaser__playVideoIcon {
        --play-video-icon-size: 3.125rem;
      }
    }

    &--sm,
    &--xs {
      .teaser__videoDuration {
        font-size: 0.75rem;
      }
    }
  }
}

.teaser--desktop {
  @include desktop-and-up {

    &--xs,
    &--sm {
      @include xs-tablet-portrait-and-up-grid;
      gap: 1rem 0.5rem;

      .teaser__videoDuration {
        font-size: 0.75rem;
      }

      .teaser__title {
        @include line-clamp(3);
      }
    }

    &--sm {
      @include xs-tablet-portrait-and-up-with-border;
    }

    &--md,
    &--xl,
    &--lg {
      @include lg-tablet-landscape-and-up-grid;
      @include lg-tablet-landscape-and-up-styles;
      .teaser__title {
        @include line-clamp(7);
        display: block;
      }

      .teaser__preview {
        @include line-clamp(5);
        display: block;
      }

      .teaser__videoDuration {
        font-size: 1rem;
      }
    }

    &--xl {
      --image-percentage: 65.63%;

      .teaser__playVideoIcon {
        --play-video-icon-size: 5.625rem;
      }
    }

    &--lg {
      --image-percentage: 54%;
    }

    &--md {
      --teaser-image-size: 47%;

      .teaser__title,
      .teaser__preview {
        @include line-clamp(3);
        display: block;
      }
    }

    &--sm,
    &--xs {
      .teaser__title {
        @include line-clamp(3);
        display: block;
      }
    }

    &--lg,
    &--md,
    &--sm,
    &--xs {
      .teaser__playVideoIcon {
        --play-video-icon-size: 4.375rem;
      }
    }
  }
}

.teaser__imageWrapper {
  grid-area: image;
  position: relative;
  border-radius: var(--rounded-corner);
  overflow: hidden; // add a border radius to the image
  height: fit-content;
}

.teaser__videoDuration {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--black-0);
  opacity: 0.9;
  color: var(--white);
  border-top-left-radius: var(--rounded-corner);
  border-bottom-right-radius: var(--rounded-corner);
  padding: 0.1875rem 0.625rem;
}

.teaser__content {
  grid-area: content;
  text-decoration: none;
}

.teaser__preview {
  display: none;
}

.teaser__img {
  border: var(--teaser-border-width) solid var(--divider-color);
}

.teaser__footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  @include ellipsis;
}

.teaser__footerAnchor {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-decoration: none;
  color: var(--primary-text-color);
  @include ellipsis;
}

.teaser__playVideoIcon {
  position: absolute;
  inset: 0;
  margin: auto;
  height: var(--play-video-icon-size);
  width: var(--play-video-icon-size);
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  overflow: hidden; // Add to enforce the icon to be a circle,
}

.teaser__publisherNameLogo {
  margin-inline-end: 0.5rem;
  display: flex;
}

.teaser__publisherName {
  @include ellipsis;
}

.publisherTime {
  @include ellipsis;
  color: var(--label-text-color);
}

.bookmarkButton {
  grid-area: bookmark;
  place-self: end;
}


.liveIndicator {
  @include tablet-portrait-and-up {
    margin-inline-end: 0.5rem;
  }
}

.liveTitle {
  display: block;
  margin-top: 0.5rem;
  @include tablet-portrait-and-up {
    display: inline;
  }
}
