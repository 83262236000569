@use "media-queries" as *;
@use "../typography/title-bold" as *;
@use "../typography/text" as *;
@use "line-clamp" as *;

$_teaser-classes: teaser--mobile, teaser--tablet-portrait,
teaser--tablet-landscape, teaser--desktop;

.teaser {
  --teaser-border-width: 1px;
  --teaser-border: var(--teaser-border-width) solid var(--divider-color);
}

@mixin tile-vertical-content-border {
  .teaser__content {
    border: var(--teaser-border);
    border-top: none;
    border-bottom-left-radius: var(--rounded-corner);
    border-bottom-right-radius: var(--rounded-corner);
  }
  .teaser__img {
    border-top-left-radius: var(--rounded-corner);
    border-top-right-radius: var(--rounded-corner);
  }

  .teaser__videoDuration {
    border-bottom-right-radius: 0;
  }
}

@mixin tile-title-font-sizes() {
  @each $class in $_teaser-classes {
    &.#{$class}--xl {
      .teaser__title {
        @extend .title-5-bold;
      }
    }

    &.#{$class}--lg,
    &.#{$class}--md,
    &.#{$class}--sm,
    &.#{$class}--xs {
      .teaser__title {
        @extend .title-7-bold;
      }
    }

    &.#{$class}--xl,
    &.#{$class}--lg {
      .teaser__preview {
        @extend .text-3;
      }
    }

    &.#{$class}--md {
      .teaser__preview {
        @extend .text-4;
      }
    }
  }
}

@mixin tile-vertical-content($sizes, $border: false) {
  @each $size in $sizes {
    &#{$size} {
      .teaser__link {
        flex-direction: column;
      }

      @if $border {
        @include tile-vertical-content-border;
      } @else {
        .teaser__img {
          border-radius: var(--rounded-corner);
        }
      }
    }
  }
}

@mixin base-horizontal-tile($image_width) {
  .teaser__link {
    flex-direction: row;
  }
  .teaser__wrapper {
    width: $image_width;
  }
  .teaser__img {
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--rounded-corner);
    border-top-left-radius: var(--rounded-corner);
  }
  .teaser__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - #{$image_width});
    border-top-right-radius: var(--rounded-corner);
    border-bottom-right-radius: var(--rounded-corner);
    border: var(--teaser-border);
    border-left: none;
    padding: 1rem 1.5rem;

    @include desktop-and-up {
      padding: 1rem 2rem;
    }
  }

  .teaser__preview {
    margin-top: 1rem;
    color: var(--label-text-color);

    @include desktop-and-up {
      margin-top: 1.5rem;
    }
  }

  .teaser__footer {
    @include desktop-and-up {
      margin-top: 1.5rem;
    }
  }

  .teaser__videoDuration {
    border-bottom-right-radius: 0;
  }
}

@mixin xs-mobile-grid {
  --teaser-xs-image-size: 19%;
  grid-template-areas:
             "content image "
             "footer bookmark";
  grid-template-columns: auto minmax(var(--interactive-element-size), var(--teaser-xs-image-size));
  grid-template-rows: auto minmax(var(--teaser-xs-image-size), var(--interactive-element-size));
}

@mixin xs-tablet-portrait-and-up-grid {
  grid-template-areas:
             "image image"
             "content content"
             "footer bookmark";
  grid-template-columns: auto var(--interactive-element-size);
  grid-template-rows: max-content auto var(--interactive-element-size);
}

@mixin xs-tablet-portrait-and-up-with-border {
  border: var(--border);
  border-top: none;
  padding: var(--padding-size);
  padding-top: 0;
  border-radius: var(--rounded-corner);

  // Little trick to allow the image to stretch out of the parents padding
  .teaser__imageWrapper {
    border-radius: var(--rounded-corner) var(--rounded-corner) 0 0;
    margin-left: var(--streach-size-with-padding);
    margin-right: var(--streach-size-with-padding);
  }
}

@mixin lg-tablet-landscape-and-up-grid {
  --image-percentage: 48.84%;
  grid-template-areas:
           "image content content"
           "image footer bookmark";
  grid-template-columns: var(--image-percentage) auto var(--interactive-element-size);
}

@mixin lg-tablet-landscape-and-up-styles {
  gap: var(--padding-size);
  padding: var(--padding-size);
  border: var(--border);
  border-radius: var(--rounded-corner);

  .teaser__title,
  .teaser__preview {
    @include line-clamp(5);
    display: block;
  }

  .teaser__content {
    display: grid;
    gap: var(--padding-size);
    grid-template-rows: auto max-content;
    height: 100%;
    align-items: end;
  }

  .teaser__footerAnchor,
  .teaser__footer{
    align-items: initial;
  }

  .teaser__title{
    align-self: end;
  }

  .teaser__playVideoIcon {
    --play-video-icon-size: 4.375rem;
  }

  // Little trick to allow the image to stretch out of the parents border
  .teaser__imageWrapper {
    border-radius: var(--rounded-corner) 0 0 var(--rounded-corner);
    margin-inline-start: var(--streach-size-with-padding);
    margin-top: var(--streach-size-with-padding);
    margin-bottom: var(--streach-size-with-padding);
  }
}

