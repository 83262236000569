.base-font {
  line-height: 1.4em;
  color: var(--primary-text-color);
}

@mixin regular() {
  @extend .base-font;
  font-weight: normal;
}

@mixin medium() {
  @extend .base-font;
  font-weight: 500;
}

@mixin bold() {
  @extend .base-font;
  font-weight: bold;
}

@mixin bold-druk() {
  @extend .base-font;
  font-family: var(--Druk);
  font-weight: bold;
  text-transform: uppercase;
}
