@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/max-root-width" as *;
@use "@/styles/typography/text" as *;

.grid {
  display: grid;
  gap: 1rem;
}

.gallery__grid {
  @include limit;

  @include tablet-portrait-and-up {
    grid-row-gap: 2rem;
  }

  @include tablet-landscape-and-up {
    grid-column-gap: 1.5rem;
  }
}

.gallery__headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sectionHeaderTitleContainer {
    text-transform: uppercase;
  }
}

.gallery__seeMoreButton {
  display: grid;
  align-self: center;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--secondary-text-color);
}

.gallery__teaser--mobile {
  &--xl,
  &--lg,
  &--md,
  &--sm,
  &--xs {
    grid-column: span 12;

    @include mobile-only {
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--divider-color);
        padding-bottom: 1.25rem;
      }
    }
  }
}

.gallery__teaser--tablet-portrait {
  @include tablet-portrait-only {
    &--lg {
      grid-column: span 8;
    }
    &--md {
      grid-column: span 6;
    }
    &--sm {
      grid-column: span 4;
    }
    &--xs {
      grid-column: span 3;
    }
  }
}

.gallery__teaser--tablet-landscape {
  @include tablet-landscape-only {
    &--lg {
      grid-column: span 8;
    }
    &--md {
      grid-column: span 6;
    }
    &--sm {
      grid-column: span 4;
    }
    &--xs {
      grid-column: span 3;
    }
  }
}

.gallery__teaser--desktop {
  @include desktop-and-up {
    &--lg {
      grid-column: span 8;
    }
    &--md {
      grid-column: span 6;
    }
    &--sm {
      grid-column: span 4;
    }
    &--xs {
      grid-column: span 3;
    }
  }
}

.galleryItems {
  --of-grid-gap-size: 2.5rem;

  display: grid;
  grid-gap: var(--of-grid-gap-size);
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @include mobile-only {
    --of-grid-gap-size: 1.5rem;
  }

  @include tablet-portrait-only {
    --of-grid-gap-size: 1rem;
  }
}

.loadMoreButton {
  justify-self: center;
  margin-top: 1rem;
}
